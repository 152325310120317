import { dia, shapes } from "@clientio/rappid";

import { Message } from "./stencil.shapes";
import Rectangle from "./custom/cw.rectangle";
import Circle from "./custom/cw.circle";
import Diamond from "./custom/cw.diamond";

declare module "@clientio/rappid" {
  namespace shapes {
    namespace stencil {
      class Message extends dia.Element {}
    }
    namespace app {
      class Rectangle extends dia.Element {}
      class Circle extends dia.Element {}
      class Diamond extends dia.Element {}
    }
  }
}

Object.assign(shapes, {
  stencil: {
    Message,
  },
  app: {
    Rectangle,
    Circle,
    Diamond,
  },
});
