import React, { useEffect, useRef } from "react";
import { dia, ui, shapes, linkTools, highlighters } from "@clientio/rappid";
import "./App.css";
import _ from "lodash";
import "./shapes/index";
import { PADDING_L, PADDING_S } from "./theme";
import { shapes as stencilConfig } from "./shapes/stencil.config";
import { getShape, getStart, Start } from "./shapes/utils";

const App = () => {
  const canvasRef: any = useRef<any>(null);
  const stencilRef: any = useRef<any>(null);
  const toolbarRef: any = useRef<any>(null);
  const inspectorRef: any = useRef<any>(null);
  const graph = React.useMemo(() => new dia.Graph({}, { cellNamespace: shapes }), []);

  useEffect(() => {
    if (!graph) return;

    const paper = new dia.Paper({
      model: graph,
      background: { color: "#F3F7F6" },
      drawGrid: true,
      width: 800,
      height: 800,
      gridSize: 10,
      clickThreshold: 10,
      frozen: true,
      async: true,
      sorting: dia.Paper.sorting.APPROX,
      cellViewNamespace: shapes,
      markAvailable: true,
      linkPinning: false,
      snapLinks: {
        radius: 20,
      },
      validateConnection: (cellViewS, magnetS, cellViewT, magnetT, end, linkView) => {
        // Prevent linking from input ports
        if (magnetS && magnetS.getAttribute("port-group") === "in") return false;
        // Prevent linking from output ports to input ports within one element
        if (cellViewS === cellViewT) return false;
        // Prevent linking to output ports
        return magnetT && magnetT.getAttribute("port-group") === "in";
      },
      validateMagnet: (cellView, magnet) => {
        // Prevent links from ports that already have a link
        var port = magnet.getAttribute("port");
        var links = graph.getConnectedLinks(cellView.model, { outbound: true });
        var portLinks = _.filter(links, function (o) {
          return o.get("source").port === port;
        });

        if (portLinks.length > 0) return false;
        // Note that this is the default behaviour. It is shown for reference purposes.
        // Disable linking interaction for magnets marked as passive
        return magnet.getAttribute("magnet") !== "passive";
      },
    });

    paper.options.defaultRouter = {
      name: "manhattan",
      args: {
        padding: { bottom: 16, vertical: 16, horizontal: 16, top: 16 },
        step: 16,
      },
    };

    paper.options.defaultConnector = {
      name: "rounded",
    };

    paper.options.defaultLink = (cellView, magnet) => {
      console.log("CellView", cellView);
      console.log("Magnet", magnet.getAttribute("port-group"));

      let dashArray = 0; //set default value
      if (magnet.getAttribute("port-group") === "error") dashArray = 3;

      const link = new shapes.standard.Link({
        attrs: {
          line: {
            stroke: "gray",
            strokeDasharray: dashArray,
          },
        },
      });

      return link;
    };

    paper.on("link:mouseenter", function (linkView) {
      if (linkView.hasTools()) return;

      const verticesTool = new linkTools.Vertices();
      const segmentsTool = new linkTools.Segments();
      const boundaryTool = new linkTools.Boundary();

      const toolsView = new dia.ToolsView({
        name: "link-hover",
        tools: [verticesTool, segmentsTool, boundaryTool],
      });

      linkView.addTools(toolsView);
    });

    paper.on("link:mouseleave", function (linkView) {
      if (linkView.hasTools("link-hover")) {
        linkView.removeTools();
      }
    });

    const snaplines = new ui.Snaplines({ paper: paper });
    snaplines.startListening();

    const scroller = new ui.PaperScroller({
      paper,
      autoResizePaper: true,
      cursor: "grab",
      scrollWhileDragging: true,
      baseHeight: 10000,
      baseWidth: 10000,
      contentOptions: {
        padding: 0,
        allowNewOrigin: "any",
      },
    });

    canvasRef.current.appendChild(scroller.el);
    //scroller.render().center();
    scroller.render();

    const toolbar = new ui.Toolbar({
      tools: ["zoomIn", "zoomOut"],
      references: {
        paperScroller: scroller,
      },
    });

    toolbarRef.current.appendChild(toolbar.el);
    toolbar.render();

    const stencil = new ui.Stencil({
      paper: scroller,
      usePaperGrid: true,
      width: 280,
      dropAnimation: true,
      search: (cell, keyword, group, stencil) => {
        var description = cell.attributes.description;
        return typeof description === "string" && description.indexOf(keyword) > -1;
      },
      groups: {
        general: { label: "General", index: 1, closed: false },
        voice: { label: "Voice Elements", index: 2, closed: false },
        messaging: { label: "Messaging", index: 3, closed: true },
        tools: { label: "Tools", index: 4, closed: true },
      },
      paperOptions: () => {
        return {
          model: new dia.Graph({}, { cellNamespace: shapes }),
          cellViewNamespace: shapes,
        };
      },
      dragStartClone: (cell: dia.Cell<dia.Cell.Attributes, dia.ModelSetOptions>) => {
        if (cell.attributes.name !== "Message") {
          console.log(cell.attributes);
          return getShape(cell.attributes.metadata.category);
          // TODO: create app shapes and clone it
          // return new shapes.standard.Rectangle({
          //   size: { width: 100, height: 50 },
          //   attrs: {
          //     body: {
          //       stroke: "grey",
          //       fontSize: "11px",
          //     },
          //   },
          //   ports: {
          //     groups: {
          //       in: PortsIn,
          //       out: PortsOut,
          //       error: PortsError,
          //     },
          //     items: [{ group: "in" }, { group: "out" }, { group: "error" }],
          //   },
          // });
        }
        return cell.clone();
        // return new shapes.basic.Circle();
      },
      layout: {
        columns: 1,
        rowGap: PADDING_S,
        rowHeight: "auto",
        marginY: PADDING_S,
        marginX: -PADDING_L,
        dx: 0,
        dy: 0,
        resizeToFit: false,
      },
    });

    stencilRef.current.appendChild(stencil.render().el);

    stencilConfig.forEach((group) => {
      const stencilShapes = group.shapes.map((s) => {
        return new shapes.stencil.Message({
          name: s.name,
          metadata: {
            category: s.metadata?.category || "rectangle",
          },
          attrs: {
            icon: {
              xlinkHref: s.xlinkHref,
            },
            label: {
              text: s.label,
            },
          },
        });
      });

      stencil.load(stencilShapes, group.name);
    });

    const data = localStorage.getItem("flow");

    graph.addCell([getStart()]);

    if (!data) {
      graph.addCell([Start]);
    } else {
      console.log("Loading from local storage...");
      graph.fromJSON(JSON.parse(data));
    }

    //graph.addCell(circle);
    paper.unfreeze();

    // inspector
    let inspector: ui.Inspector | null = null;
    let selectionFrame: highlighters.mask | null = null;

    const select = (elementView: dia.ElementView) => {
      deselect();

      selectionFrame = highlighters.mask.add(elementView, "body", "selection-frame", {
        layer: dia.Paper.Layers.BACK,
      });

      inspector = new ui.Inspector({
        cell: elementView.model,
        groups: {
          text: {
            index: 1,
            label: "Text",
          },
          presentation: {
            index: 2,
            label: "Presentation",
          },
        },
        inputs: {
          "attrs/label/text": {
            label: "Label",
            type: "content-editable",
            group: "text",
          },
          "data/message": {
            label: "message",
            type: "content-editable",
            group: "text",
          },
          "attrs/body/fill": {
            label: "Fill Color",
            type: "color",
            group: "presentation",
          },
        },
      });

      inspector.render();
      inspectorRef.current.appendChild(inspector.el);
    };

    const deselect = () => {
      if (inspector) {
        inspector.remove();
        inspector = null;
      }

      if (selectionFrame) {
        selectionFrame.remove();
        selectionFrame = null;
      }
    };

    paper.on("element:pointerclick", (elementView) => {
      select(elementView);
    });

    paper.on("blank:pointerclick", () => {
      deselect();
    });

    stencil.on("element:drop", (elementView: dia.ElementView) => {
      select(elementView);
    });

    return () => {
      snaplines.stopListening();
      graph.clear();
      scroller.remove();
      paper.remove();
      stencil.remove();
      toolbar.remove();
    };
  }, [graph]);

  const handleClick = () => {
    const data = graph.toJSON();
    localStorage.setItem("flow", JSON.stringify(data));
  };

  return (
    <div className="d-flex w-100 h-100">
      <div className="stencil" ref={stencilRef}></div>
      <div className="canvas">
        <div className="toolbar d-flex align-items-center">
          <button className="btn btn-primary btn-sm m-2" onClick={handleClick}>
            Export
          </button>
          <div ref={toolbarRef} />
        </div>
        <div style={{ height: "100%", width: "100%" }} ref={canvasRef}></div>
      </div>

      <div className="properties" ref={inspectorRef}></div>
    </div>
  );
};

export default App;
