import { Icons } from "../theme";

export const shapes = [
  {
    name: "general",
    shapes: [
      {
        name: "set-variable",
        xlinkHref: Icons.general.setVariable,
        label: "Set variable",
      },
      {
        name: "get-attribute",
        xlinkHref: Icons.general.getAttribute,
        label: "Get attribute",
      },
      {
        name: "get-attributes",
        xlinkHref: Icons.general.getAttributes,
        label: "Get attributes",
      },
      {
        name: "set-attribute",
        xlinkHref: Icons.general.setAttribute,
        label: "Set attribute",
      },
      {
        name: "delete-attribute",
        xlinkHref: Icons.general.deleteAttribute,
        label: "Delete attribute",
      },
      {
        name: "run-subflow",
        xlinkHref: Icons.general.runSubflow,
        metadata: {
          category: "circle",
        },
        label: "Run subflow",
      },
      {
        name: "if",
        metadata: {
          category: "diamond",
        },
        xlinkHref: Icons.general.if,
        label: "If",
      },
    ],
  },
  {
    name: "voice",
    shapes: [
      {
        name: "set-voice",
        xlinkHref: Icons.voice.setVoice,
        label: "Default Voice",
      },
      {
        name: "play",
        xlinkHref: Icons.voice.play,
        label: "Play",
      },
      {
        name: "menu",
        xlinkHref: Icons.voice.menu,
        label: "Menu",
      },
      {
        name: "forward",
        xlinkHref: Icons.voice.forward,
        label: "Forward",
      },
      {
        name: "record",
        xlinkHref: Icons.voice.record,
        label: "Record",
      },
      {
        name: "make-call",
        xlinkHref: Icons.voice.makeCall,
        label: "Make call",
      },
      {
        name: "queue",
        xlinkHref: Icons.voice.queue,
        label: "Queue",
      },
      {
        name: "hangup",
        xlinkHref: Icons.voice.hangup,
        label: "Hangup",
      },
    ],
  },
  {
    name: "messaging",
    shapes: [
      {
        name: "send-sms",
        xlinkHref: Icons.messaging.sendSms,
        label: "Send SMS",
      },
      {
        name: "send-email",
        xlinkHref: Icons.messaging.sendEmail,
        label: "Send Email",
      },
    ],
  },
  {
    name: "tools",
    shapes: [
      {
        name: "run-function",
        xlinkHref: Icons.tools.runFunction,
        label: "Run function",
      },
      {
        name: "http-request",
        xlinkHref: Icons.tools.httpRequest,
        label: "HTTP Request",
      },
      {
        name: "get-config",
        xlinkHref: Icons.tools.getConfig,
        label: "Get Configuration",
      },
    ],
  },
];
