import { dia } from "@clientio/rappid";
import { ObjectHash } from "backbone";

import { FONT_FAMILY, PADDING_L, LIGHT_COLOR, TWITTER_ICON } from "../theme";

export enum ShapeTypesEnum {
  MESSAGE = "stencil.Message",
}

const SHAPE_SIZE = 32;

export const Message = dia.Element.define(
  ShapeTypesEnum.MESSAGE,
  {
    name: "Message",
    metadata: {
      category: "rectangle",
    },
    size: { width: SHAPE_SIZE, height: SHAPE_SIZE },
    attrs: {
      body: {
        fill: LIGHT_COLOR,
        stroke: "grey",
        refCx: "50%",
        refCy: "50%",
        refR: "50%",
      },
      icon: {
        width: 20,
        height: 20,
        refX: "50%",
        refY: "50%",
        x: -10,
        y: -10,
        xlinkHref: TWITTER_ICON,
      },
      label: {
        text: "Component",
        refDx: PADDING_L,
        refY: "50%",
        textAnchor: "start",
        textVerticalAnchor: "middle",
        fill: "#242424",
        fontFamily: FONT_FAMILY,
        fontSize: 11,
      },
    },
  } as ObjectHash,
  {
    markup: [
      {
        tagName: "circle",
        selector: "body",
      },
      {
        tagName: "image",
        selector: "icon",
      },
      {
        tagName: "text",
        selector: "label",
      },
    ],
  }
);
