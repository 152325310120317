export const PortsError = {
  position: {
    name: "bottom",
  },
  attrs: {
    portBody: {
      magnet: true,
      r: 6,
      fill: "red",
      stroke: "red",
    },
  },
  label: {
    position: {
      name: "left",
      args: { y: 6 },
    },
    markup: [
      {
        tagName: "text",
        selector: "label",
        className: "label-text",
      },
    ],
  },
  markup: [
    {
      tagName: "circle",
      selector: "portBody",
    },
  ],
};
