export const CircularPort = (position = "right", color = "green") => {
  const definition = {
    position: {
      name: position,
    },
    attrs: {
      portBody: {
        magnet: true,
        r: 5,
        fill: color,
        stroke: color,
      },
    },
    label: {
      position: {
        name: "left",
        args: { y: 6 },
      },
      markup: [
        {
          tagName: "text",
          selector: "label",
          className: "label-text",
        },
      ],
    },
    markup: [
      {
        tagName: "circle",
        selector: "portBody",
      },
    ],
  };

  return definition;
};
