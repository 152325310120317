import { dia } from "@clientio/rappid";
import { CircularPort } from "../../ports";
import { ShapeAttributes, TWITTER_ICON } from "../../theme";

export default class Circle extends dia.Element {
  defaults(): Partial<dia.Element.Attributes> {
    return {
      ...super.defaults,
      type: "app.Circle",
      size: { width: ShapeAttributes.width, height: ShapeAttributes.height },
      attrs: {
        body: {
          cx: ShapeAttributes.circle.cx,
          cy: ShapeAttributes.circle.cy,
          r: ShapeAttributes.circle.r,
          strokeWidth: ShapeAttributes.strokeWidth,
          stroke: ShapeAttributes.stroke,
          fill: ShapeAttributes.fill,
        },
        icon: {
          width: ShapeAttributes.icon.width,
          height: ShapeAttributes.icon.height,
          refX: ShapeAttributes.icon.refX,
          refY: ShapeAttributes.icon.refY,
          x: ShapeAttributes.icon.x,
          y: ShapeAttributes.icon.y,
          xlinkHref: TWITTER_ICON,
        },
      },
      markup: [
        {
          tagName: "circle",
          selector: "body",
        },
        {
          tagName: "image",
          selector: "icon",
        },
      ],
      ports: {
        groups: {
          in: CircularPort("left", ShapeAttributes.port.in),
          error: CircularPort("bottom", ShapeAttributes.port.error),
          out: CircularPort("right", ShapeAttributes.port.out),
        },
        items: [{ group: "in" }, { group: "error" }, { group: "out" }],
      },
    };
  }
}
