import { shapes } from "@clientio/rappid";
import { PortsIn, PortsOut, PortsError, CircularPort } from "../ports";
import { Icons, ShapeAttributes } from "../theme";

export const Start = new shapes.standard.Circle({
  size: { width: ShapeAttributes.width, height: ShapeAttributes.height },
  position: { x: 250, y: 200 },
  attrs: {
    body: {
      stroke: "grey",
      fontSize: "11px",
    },
    icon: {
      width: ShapeAttributes.icon.width,
      height: ShapeAttributes.icon.height,
      refX: ShapeAttributes.icon.refX,
      refY: ShapeAttributes.icon.refY,
      x: ShapeAttributes.icon.x,
      y: ShapeAttributes.icon.y,
      xlinkHref: Icons.general.start,
    },
  },
  markup: [
    {
      tagName: "circle",
      selector: "body",
    },
    {
      tagName: "image",
      selector: "icon",
    },
    {
      tagName: "text",
      selector: "label",
    },
  ],
  ports: {
    groups: {
      out: CircularPort(),
    },
    items: [{ group: "out" }],
  },
});

export const Play = new shapes.standard.Rectangle({
  size: { width: 64, height: 64 },
  attrs: {
    body: {
      stroke: "grey",
      fontSize: "11px",
    },
  },
  ports: {
    groups: {
      in: PortsIn,
      out: PortsOut,
      error: PortsError,
    },
    items: [{ group: "in" }, { group: "out" }, { group: "error" }],
  },
});

export const getStart = () => {
  return Start;
};

export const getShape = (type: string) => {
  console.log("Shape type", type);
  if (type === "circle") return new shapes.app.Circle();
  if (type === "diamond") return new shapes.app.Diamond();
  return new shapes.app.Rectangle();
};
